import "./header.scss";
import React, { Component, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router";

import { StyledButton } from "../../Shared/Button/button.style";
import { AuthContext } from "../../../contexts/AuthContext";
import { api, get, post, put } from "../../../utils/api";
import { useMutation, useQueries, useQuery } from "react-query";
import logo from "../../../img/logo-w.png";

const Forum = (props) => {
  const token = localStorage.getItem("maths-user");
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { user, setUser } = useContext(AuthContext);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [routes, setRoutes] = useState([
    {
      name: "Courses",
      route: "/courses",
      icon: `<i className="hidden md:inline fas fa-book text-white"></i>`,
    },
    {
      name: "Challenges",
      route: "/challenge",
      icon: `<i className="hidden md:inline fas fa-graduation-cap text-white"></i>`,
    },
    {
      name: "Subscriptions",
      route: "/subscriptions",
      icon: `<i className="hidden md:inline fas fa-envelope-open text-white"></i>`,
    },
    // {
    //   name: "Forum",
    //   route: "/forum?page=1",
    //   icon: `<i className="hidden md:inline fas fa-file text-white"></i>`,
    // },
    {
      name: "Users",
      route: "/users",
      icon: `<i className="hidden md:inline fas fa-users text-white"></i>`,
    },
    {
      name: "Exams",
      route: "/exams",
      icon: `<i className="hidden md:inline fas fa-question-circle text-white"></i>`,
    },
    // {
    //   name: "Games",
    //   route: "/faq",
    //   icon: `<i className="hidden md:inline fas fa-basketball-ball text-white"></i>`,
    // },
    // {
    //   name: "Glossary",
    //   route: "/glossary",
    //   icon: `<i className="hidden md:inline fas fa-star text-white"></i>`,
    // },
  ]);

  const {
    isLoading: loadingData,
    error,
    data,
    refetch,
  } = useQuery("formumData", () =>
    get(`users/profile`, true).then((res) => {
      setUser(res.data.user);
    })
  );

  const toggle = () => {
    setOpen(!open);
  };

  const toggleDropDown = () => {
    setOpenDropDown(!openDropDown);
  };

  function handleClick(route) {
    history.push(route);
  }

  const logOut = () => {
    localStorage.clear();
    history.push("/");
  };

  return (
    <div>
      <div
        className="w-full authenticated__header fixed z-10 bg-white py-6 shadow"
        id=""
      >
        <nav className="boxed__container w-full z-50  flex items-center justify-between flex-wrap py-2 ">
          <div className="flex items-center flex-no-shrink text-white ">
            <Link to="/courses">
              <div className="flex items-center flex-no-shrink text-black ">
                <p className="link__text">
                  <img src={logo} alt="Next Gen Learners" width="120" />
                </p>
              </div>
            </Link>
          </div>
          <div className="block lg:hidden">
            <button
              onClick={toggle}
              className="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light"
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            className={
              open
                ? "block w-full flex-grow lg:flex sm:items-center lg:w-auto"
                : "hidden w-full flex-grow lg:flex sm:items-center lg:w-auto"
            }
          >
            {/* <div className=""> */}
            <div className="text-sm lg:flex-grow"></div>
            {/* <div> */}
            {routes.map((route, index) => (
              <main key={index}>
                <div dangerouslySetInnerHTML={{ __html: route.icon }}></div>
                <Link
                  to={route.route}
                  className="link__text block paragraph ml-1 lg:inline-block mt-4 lg:mt-0 mr-6"
                >
                  {route.name}
                </Link>
              </main>
            ))}
            {token ? (
              <a
                href="#"
                className="link__text block paragraph ml-1 lg:inline-block mt-4 lg:mt-0 mr-6"
                onClick={() => {
                  logOut()
                }}
              >
                Logout
              </a>
            ) : (
              <StyledButton
                onClick={() => {
                  handleClick("/login");
                }}
                backgroundColor="#fff"
                color="#286cff"
              >
                Login
              </StyledButton>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default withRouter(Forum);
