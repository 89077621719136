import React, { Component, useState } from "react";
import "./loader.css";

const Loader = (props) => {
  return (
    <main className="flex justify-center">
      <div className="loader"></div>
    </main>
  );
};
export default Loader;
