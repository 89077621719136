import React, { Component, useEffect, useState } from "react";
import Header from "../../components/Shared/Header";
import "./index.scss";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post } from "../../utils/api";
import { toast } from "react-toastify";
import moment from "moment";

const Users = (props) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    fetchUsers();
  }, []);

  const handleDeactivateUser = (user) => {
    // console.log({user});
    const route = user.status == 'active' ? 'deactivate' : 'activate';

    post(`users/profile/${user._id}/${route}`, {}, true).then((res) => {
      fetchUsers();
    }).catch(() => {
      toast.error('An error occured');
    });
  }

  const fetchUsers = () => {
    get(`users/list`, {}, true).then((res) => {
      setUsers(res.data.users);
    }).catch(() => {
      toast.error('An error occured');
    });
  }

  const handlePurgeDB = () => {
    get(`users/purge-db`, true).then((res) => {
      toast('Purged successfully');
    }).catch(() => {
      toast.error('An error occured');
    });
  }

  const handleDownloadCsv = async () => {

    setLoading(true);
    // await get('', true);

    const link = document.createElement("a");
    link.href =
      `${process.env.REACT_APP_API_URL}users/list-csv`;
    link.setAttribute("download", "users.xls");
    link.click();

    setLoading(false);
  }

  return (
    <div className="course__page ">
      <Header />
      <div className="py-24 ">
        {/* <a href="#" onClick={handlePurgeDB}>Purge DB</a> */}

        <a href="#" className="p-4 bg-blue-700 rounded-md text-white" disabled={loading} onClick={handleDownloadCsv}>{loading ? 'Please wait...' : 'Download CSV'}</a>
        <main className="boxed__container">
          <div className="flex flex-col boxed_-container">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden sm:rounded-lg shadow-md">
                  <table className="min-w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="text-xs font-medium text-gray-700 px-6 py-3 text-left uppercase tracking-wider"
                        >
                          First Name
                        </th>
                        <th
                          scope="col"
                          className="text-xs font-medium text-gray-700 px-6 py-3 text-left uppercase tracking-wider"
                        >
                          Last Name
                        </th>
                        <th
                          scope="col"
                          className="text-xs font-medium text-gray-700 px-6 py-3 text-left uppercase tracking-wider"
                        >
                          Child Year
                        </th>
                        <th
                          scope="col"
                          className="text-xs font-medium text-gray-700 px-6 py-3 text-left uppercase tracking-wider"
                        >
                          Username
                        </th>
                        <th
                          scope="col"
                          className="text-xs font-medium text-gray-700 px-6 py-3 text-left uppercase tracking-wider"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="text-xs font-medium text-gray-700 px-6 py-3 text-left uppercase tracking-wider"
                        >
                          Phone
                        </th>
                        <th
                          scope="col"
                          className="text-xs font-medium text-gray-700 px-6 py-3 text-left uppercase tracking-wider"
                        >
                          Date Signed Up
                        </th>
                        <th
                          scope="col"
                          className="text-xs font-medium text-gray-700 px-6 py-3 text-left uppercase tracking-wider"
                        >
                          Channel
                        </th>
                        <th
                          scope="col"
                          className="text-xs font-medium text-gray-700 px-6 py-3 text-left uppercase tracking-wider"
                        >
                          Subscription PLan
                        </th>
                        {/* <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Edit</span>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {users &&
                        users.map((user) => {
                          return (
                            <tr key={user._id} className="bg-white border-b">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {user.firstname}
                              </td>
                              <td className="text-sm text-gray-500 px-6 py-4 whitespace-nowrap">
                                {user.lastname}
                              </td>
                              <td className="text-sm text-gray-500 px-6 py-4 whitespace-nowrap">
                                {user.childYear}
                              </td>
                              <td className="text-sm text-gray-500 px-6 py-4 whitespace-nowrap">
                                {user.username}
                              </td>
                              <td className="text-sm text-gray-500 px-6 py-4 whitespace-nowrap">
                                {user.email}
                              </td>
                              <td className="text-sm text-gray-500 px-6 py-4 whitespace-nowrap">
                                {user.phone}
                              </td>
                              <td className="text-sm text-gray-500 px-6 py-4 whitespace-nowrap">
                                {moment(user.createdAt).format('LLL')}
                              </td>
                              <td className="text-sm text-gray-500 px-6 py-4 whitespace-nowrap">
                                {user.location}
                              </td>
                              <td className="text-sm text-gray-500 px-6 py-4 whitespace-nowrap">
                                {user.subscription_plan?.name} {user.subscription?.is_trial && ' - Trial Plan'}
                                <br />{user.subscription?.is_active ? '(Active)' : '(Inactive)'}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <a
                                  href="#"
                                  className="text-blue-600 hover:text-blue-900"
                                  onClick={() => handleDeactivateUser(user)}
                                >
                                  {user.status == 'active' ? "Deactivate" : "Activate"}
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Users;
