import styled from "styled-components";

export const StyledProgress = styled.div`
  width: ${(props) => (props.width ? props.width : "50%")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#2870FD"};
  border-radius: 0.25rem;
  height: 8px;
  opacity: 1;
`;
