import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./views/Auth/Login";
import About from "./views/About";
import Forum from "./views/Forum";
import Exams from "./views/Exams";
import CreateExam from "./views/Exams/create";
import UpdateExam from "./views/Exams/update";
import ForumCategories from "./views/Forum/categories";
import ForumByCategory from "./views/Forum/byCategory";
import Chat from "./views/Chat";
import Post from "./views/Post";
import FAQs from "./views/FAQs";
import User from "./views/User";
import Users from "./views/Users";
import Dashboard from "./views/Dashboard";
import Challenges from "./views/Challenges";
import SingleChallenge from "./views/Challenges/single";
import Glossary from "./views/Glossary";
import GlossarySearch from "./views/Glossary/search";
import Account from "./views/Account";
import Course from "./views/Course";
import EditCourse from "./views/Course/edit";
import CreateCourse from "./views/Course/create";
import SingleCourse from "./views/Course/single";
import CreateCourseLesson from "./views/Course/createLesson";
import EditCourseLesson from "./views/Course/editLesson";
import EditChallengeLesson from "./views/Challenges/editLesson";
import CreateCourseAssesment from "./views/Course/createAssesment";
import CreateChallengeAssesment from "./views/Challenges/createAssesment";
import CreateChallenge from "./views/Challenges/create";
import EditChallenge from "./views/Challenges/edit";
import CreateChallengeLesson from "./views/Challenges/createLesson";
import Error from "./views/Error";
import Photo from "./views/Account/photo";
import Me from "./views/Account/view";
import Subscription from "./views/Subscription";
import CreateSubscription from "./views/Subscription/create";
import AuthContextProvider from "./contexts/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import ProtectedRoute from "./components/ProtectedRoute";
import "react-toastify/dist/ReactToastify.css";
import {
  useQuery,
  useMutation,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import EditLessonAssessnment from "./views/Course/editAssesment";
import CreatePDF from "./views/Course/pdf/createPDF";
import ManagePDF from "./views/Course/pdf/index";
import EditPDF from "./views/Course/pdf/editPDF";

import CreateExamPDF from "./views/Exams/pdf/createPDF";
import ManageExamPDF from "./views/Exams/pdf/index";
import EditExamPDF from "./views/Exams/pdf/editPDF";
import ShowCategory from "./views/Exams/showCategory";
import EditCategory from "./views/Exams/editCategory";


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
function App() {
  return (
    <QueryClientProvider className="app" client={queryClient}>
      <ToastContainer />
      <AuthContextProvider>
        <BrowserRouter>
          <div className=" font-sans">
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/about" component={About} />
              {/* <ProtectedRoute
                path="/forum/categories"
                component={ForumCategories}
              />
              <ProtectedRoute
                path="/forum/category/:categoryId"
                component={ForumByCategory}
              />
              <ProtectedRoute path="/forum" component={Forum} /> */}
              <ProtectedRoute path="/post/:postId" component={Post} />
              <Route path="/faqs" component={FAQs} />
              <ProtectedRoute path="/user/:userId" component={User} />
              <ProtectedRoute path="/courses" component={Dashboard} />
              <ProtectedRoute path="/course/edit/:id" component={EditCourse} />
              <ProtectedRoute
                path="/course/:id/create"
                component={CreateCourseLesson}
              />
              <ProtectedRoute
                path="/course/:id/:lesson/create-assesment"
                component={CreateCourseAssesment}
              />
              <ProtectedRoute
                path="/course/:id/:lesson/edit-assesment"
                component={EditLessonAssessnment}
              />
              <ProtectedRoute
                path="/course/:id/:lesson/manage-pdf"
                component={ManagePDF}
              />
              <ProtectedRoute
                path="/course/:id/:lesson/add-pdf"
                component={CreatePDF}
              />
              <ProtectedRoute
                path="/course/:id/:lesson/:pdfId/edit-pdf"
                component={EditPDF}
              />
              <ProtectedRoute path="/course/:id" component={SingleCourse} />
              <ProtectedRoute
                path="/lesson/course/:lessonId"
                component={EditCourseLesson}
              />
              <ProtectedRoute
                path="/lesson/challenge/:lessonId"
                component={EditChallengeLesson}
              />
              <ProtectedRoute
                path="/glossary/search/:tag"
                component={GlossarySearch}
              />
              <ProtectedRoute path="/glossary" component={Glossary} />
              <ProtectedRoute path="/account" component={Account} />
              <ProtectedRoute
                path="/create/challenge"
                component={CreateChallenge}
              />
              <ProtectedRoute
                path="/challenge/:id/create"
                component={CreateChallengeLesson}
              />
              <ProtectedRoute
                path="/challenge/:id/:lesson/create-assesment"
                component={CreateChallengeAssesment}
              />
              <ProtectedRoute
                path="/challenge/edit/:id"
                component={EditChallenge}
              />
              <ProtectedRoute
                path="/challenge/:id"
                component={SingleChallenge}
              />
              <ProtectedRoute path="/challenge" component={Challenges} />
              <ProtectedRoute path="/users" component={Users} />
              <ProtectedRoute path="/messages" component={Chat} />
              <ProtectedRoute path="/create/course" component={CreateCourse} />
              <ProtectedRoute path="/course/:courseId" component={Course} />
              <ProtectedRoute path="/photo" component={Photo} />
              <ProtectedRoute path="/me" component={Me} />
              <ProtectedRoute path="/exams/create" component={CreateExam} />
              <ProtectedRoute
                path="/exams/update/:examId"
                component={UpdateExam}
              />
              <ProtectedRoute path="/exams" component={Exams} />
              <ProtectedRoute
                path="/exam/:id/manage-pdf"
                component={ManageExamPDF}
              />
              <ProtectedRoute
                path="/exam/:id/add-pdf"
                component={CreateExamPDF}
              />
              <ProtectedRoute
                path="/exam/:id/:pdfId/edit-pdf"
                component={EditExamPDF}
              />
              <ProtectedRoute
                path="/exam/:id"
                component={ShowCategory}
              />

              <ProtectedRoute
                path="/category/:id/update"
                component={EditCategory}
              />


              <ProtectedRoute
                path="/subscriptions/create"
                component={CreateSubscription}
              />
              <ProtectedRoute path="/subscriptions" component={Subscription} />
              <Route path="*" exact={true} component={Error} />
            </Switch>
          </div>
        </BrowserRouter>
      </AuthContextProvider>
    </QueryClientProvider>
  );
}

export default App;
